import logo from "../assets/logo.webp"
import { Link } from "react-router-dom";

const P404 = () => {
    return (
        <div className="question">
            <div className="question__top">
                <Link className="arrow-back" to="/">‹ Zpět</Link>
                <div className="logo"><img src={logo} alt="Logo E-learning" /></div>
            </div>
            <div className="question__center">
                <h2>Stránka nebyla nalezena</h2>
                <div className="textExplanation"><Link className="arrow-back" to="/">‹ Přejít na domovskou stránku</Link></div>
            </div>
        </div>
    )
}

export default P404;