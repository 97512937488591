import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/index.scss";
import { IconContext } from '@phosphor-icons/react';
import { ToastProvider } from './components/Toast/useToast';
import { APIProvider } from './hooks/useAPI';
import { BrowserRouter } from 'react-router-dom';

const host = window.location.host;

export const APIURL = host.includes('localhost') ? "http://127.0.0.1:8000/api" : "https://nehodananecisto.cz/api";
export const STORAGEURL = host.includes('localhost') ? "https://vrnew.agenturagen-preview.cz" : "https://nehodananecisto.cz";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <ToastProvider>
        <APIProvider>
          <IconContext.Provider value={{size:'1.5rem'}}>
            <BrowserRouter>
              <App />  
            </BrowserRouter>
          </IconContext.Provider>
        </APIProvider>
      </ToastProvider>
);