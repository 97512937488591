import { useEffect, useRef, useState } from "react";
import Timer from "../components/UI/Timer"
import {CSSTransition} from 'react-transition-group';
import { useQuiz } from "../hooks/useQuiz";
import parse from 'html-react-parser';
import logo from "../assets/logo.webp"
import trophy from "../assets/mage_trophy.png"
import timeout from '../assets/svg/timeout.svg'
import ReactPlayer from "react-player";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Car, PersonSimpleWalk } from "@phosphor-icons/react";
import Item from "../components/UI/Item";
import { STORAGEURL } from "..";
import Loading from "../components/UI/Loading";

const Quiz = () => {
    const {openQuiz, currentQuiz, saveAnswer, getUnseen, closeQuiz} = useQuiz();
    let { name } = useParams();
    const navigate = useNavigate();
    const player = useRef();

    const [scene, setScene] = useState('intro');
    const [choice, setChoice] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);

    const [timerShow, setTimerShow] = useState(false);
    const [questionsShow, setquestionsShow] = useState(false);
    const [videoSrc, setVideoSrc] = useState(null);

    useEffect(()=>{
        let id = name?.split("-").slice(-1)[0];
        if (id&&parseInt(id)!==NaN) {
            openQuiz(id);
            setScene('intro');
        } else {
            navigate("/404");
        }
    }, [name])

    useEffect(()=>{
        setVideoSrc(STORAGEURL+currentQuiz?.situation)
    }, [currentQuiz])

    useEffect(()=>{
        switch (scene) {
            case "intro":
                setChoice(null);
                setIsCorrect(null);
                setTimerShow(false);
                setquestionsShow(false)
                break;
            case "running":
                setTimerShow(true); 
                setquestionsShow(true);
                break;
            case "explanation": 
                if (choice) {
                    setVideoSrc(STORAGEURL+currentQuiz["video"+choice])
                }
                setTimerShow(false); 
                setquestionsShow(false);
                break;
            case "closing": 
                setVideoSrc(STORAGEURL+currentQuiz.sumvideo)
                break;
            case "next": 
                setquestionsShow(false);
                break;
        }
    }, [scene])

    const videoBtnClick = () => {
        setScene(scene==='closing' ? 'next' : scene==='intro'?'running':'closing')
    }


    const sendAnswer = (order) => {
        setChoice(order);
        setIsCorrect(order==currentQuiz.correct);
        setTimerShow(false);
        saveAnswer({question:currentQuiz.id,answer:order});
        setVideoSrc(null)
        
        setTimeout(()=>{setScene('explanation')}, 3000);
    }

    
    if (currentQuiz) {return (
        <div className="question">
            <div className="question__box">
                <div className="question__top">
                    {(scene==='intro'||scene==='next')?<Link className="arrow-back" to="/" onClick={closeQuiz}>{scene==='next'?'× ukončit':"‹ Zpět"} </Link>:<a></a>}
                    <div className="logo"><img src={logo} alt="Logo E-learning" /></div>
                    <div className="question__top__right">
                        {scene==='next'?<></>:<>
                            <div>
                                <b>{currentQuiz.name}</b>
                                <p>{ currentQuiz.pov }</p>
                            </div>
                            <div className="icon">
                                {currentQuiz.pov.includes('chodce')?<PersonSimpleWalk />:<Car /> }
                            </div>
                        </>}
                    </div>
                </div>
                {scene!=='next' ? (
                    <div className={"question__center "+scene}>
                        <CSSTransition in={scene==='intro'||scene==='running'} unmountOnExit={true} appear={true} timeout={300} classNames='slide-right'>
                            <h2>{ parse(currentQuiz.question) }</h2>
                        </CSSTransition>
                        <CSSTransition in={isCorrect!==null&&(scene=='explanation'||scene=='closing')} unmountOnExit={true} appear={true} timeout={300} classNames='slide-left'>
                            <div className="textExplanation">
                                {scene=='closing' ? <h4>shrnutí situace</h4> : isCorrect? <h4><strong style={{color:"#22FF6D"}}>správná</strong> odpověĎ</h4> :choice? <h4><strong style={{color:"#FF9417"}}>Špatná </strong>odpověĎ</h4> :<h4>Čas vypršel</h4>}
                                <span>{scene=='closing' ?currentQuiz.sumtext:choice?currentQuiz["text"+choice]:currentQuiz["text"+currentQuiz.correct]}</span>
                            </div>
                        </CSSTransition>
                        <CSSTransition in={questionsShow} unmountOnExit={true} appear={true} timeout={300} classNames='slide-right'>
                            <div className="question__center__items">
                                {["A) "+currentQuiz.answer1,"B) "+currentQuiz.answer2,"C) "+currentQuiz.answer3].map((que, index) => (
                                    <CSSTransition in={scene=='running'||(scene!=="intro"&&index===currentQuiz.correct-1)||(scene!=="closing"&&scene!=="intro"&&index===choice-1)} timeout={300} unmountOnExit={true} appear={true} key={index} classNames='slide-right'>
                                        <label>
                                            <input type="radio" value="index" name="que" 
                                            disabled={isCorrect!==null||scene!=='running'} onClick={()=>sendAnswer(index+1)} className={isCorrect!==null&&index==currentQuiz.correct-1?'correct':isCorrect===null?'':'wrong'} 
                                            />
                                            <p className="nextButton">{ que }</p>
                                        </label>
                                    </CSSTransition>
                                ))}
                            </div>
                        </CSSTransition>
                        {(scene=='explanation'&&!choice)? <img src={timeout} /> : 
                        <CSSTransition in={videoSrc} unmountOnExit={true} timeout={300} appear={true} classNames='slide-left'>
                            <div className={'videoplayer '+(scene=='running'?'running':scene==='explanation'?(isCorrect?'spravna':'spatna'):'')}>
                                <ReactPlayer ref={player} playing={scene!=='running'} onEnded={videoBtnClick} url={videoSrc} controls={scene!=='running'} playsinline />
                            </div>
                        </CSSTransition>}
                        <CSSTransition in={scene!=='running'} unmountOnExit={true} timeout={300} appear={true} classNames='slide-left'>
                            <button className="nextButton" onClick={videoBtnClick}>{scene==='explanation'?'přejít na shrnutí situace':scene==='closing'?'přejít na výběr situací':"Pokračovat"}</button>
                        </CSSTransition>
                    </div>
                ): getUnseen().length? (
                    <div className="question_next home__box">
                        <div className="perex">
                            <div className="perex__title center">
                                <h2>vyzkoušej si další situace</h2>
                            </div>
                        </div>
                        <div className="home__box__container">
                            {getUnseen().map(q=> <Item data={q} key={q.id} /> )}
                        </div>
                    </div>
                ) : (
                    <div className="question_next question_end home__box">
                        <img src={trophy} alt='trophy' />
                        <div className="perex">
                            <div className="perex__title center">
                                <h2>DOBRÁ práce, dokončil/A Jsi poslední e-learning!</h2>
                            </div>
                        </div>
                        <Link className="nextButton" to="../">zpět na hlavní stránku</Link>
                    </div>
                )}
            </div>
            {scene!=='next'&&<Timer visible={timerShow} onTimeout={sendAnswer} />}
            
        </div>
    )} else return (
        <div className="question">
            <Loading />
        </div>
    )
}

export default Quiz