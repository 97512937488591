import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { initCookieConsent, showCookieConsent } from "./components/UI/Cookies";
import { QuizProvider } from "./hooks/useQuiz";
import Home from "./routes/Home";
import Quiz from "./routes/Quiz";
import P404 from "./routes/404";

const App = () => {
    useEffect(() => {
        // if (process.env.NODE_ENV === 'production') {
          initCookieConsent();
        // }
      }, []);
    return (
        <QuizProvider>
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route exact path="/404" element={<P404/>} />
                <Route exact path="/:name" element={<Quiz/>} />
                <Route exact path="*" element={<P404/>} />
            </Routes>
            <footer>Copyright © 2024 Platforma VIZE 0 | <a onClick={showCookieConsent}>Nastavení soukromí</a></footer>
        </QuizProvider>
    );
};

export default App;
