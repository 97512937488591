import { createContext, useContext, useEffect, useState } from "react";
import { useAPI } from "./useAPI";
import { useNavigate } from "react-router-dom";

const QuizContext = createContext();
export const QuizProvider = ({ children }) => {
    const {get, post} = useAPI();
    const [allQuizes, setAllQuizes] = useState([]);
    const [currentQuiz, setCurrentQuiz] = useState(null);
    const [answers, setAnswers] = useState([]);
    const navigate = useNavigate();

    const fetch = async () => {
        await post('/collection/items', {type:'quiz', language: 'cs', orderAsc: "ASC", limit: 20, page:1}).then((response=>{
            setAllQuizes(response);
        }))
    }
    const openQuiz = async (id) => {
        setCurrentQuiz(null);
        const isRepeat = answers.some(a => a.question === id);
        await post('/collection/item',{id_group:id, language:'cs'}).then((response=>{
            if (response&&response.question) {
                setCurrentQuiz(response);
                window.dataLayer.push({
                    event: 'question_opened',
                    questionId: id,
                    isRepeat
                });
    
            } else {
                navigate("/404");
            }
            
        }))
    }

    const closeQuiz = () => {
        setCurrentQuiz(null);
        navigate("/")
    }

    const saveAnswer = (data) => {
        const isNewAnswer = answers.every(a => a.question !== data.question);
        setAnswers(prevAnsw => {
            isNewAnswer ? prevAnsw.push(data) : (prevAnsw.find(a=>a.question==data.question).answer = data.answer);
            return prevAnsw
        })
        localStorage.setItem("answers", JSON.stringify(answers));
        window.dataLayer.push({
            event: isNewAnswer?'new_answer':'reanswer',
            questionId: data.question,
            answer: data.answer
        });
    
    }
    const getLocalStorage = () => {        
        let ls = JSON.parse(localStorage.getItem('answers')||"[]");
        if (!localStorage.getItem('updated')) {
            ls.forEach(e => {
                window.dataLayer.push({
                    event: 'backfill_answer',
                    questionId: e.question, 
                    answer: e.answer
                });
            });
            localStorage.setItem('updated', true)
        }
        setAnswers(ls);
    }

    useEffect(()=>{
        fetch();
        getLocalStorage();
    }, [])

    const getUnseen = (id) => {
        if (id) return answers.find(a => a.question==id)
        else return allQuizes.items.filter(q=>!answers.find(a => a.question==q.id))
    }

    return <QuizContext.Provider value={{allQuizes, currentQuiz, openQuiz, saveAnswer, getUnseen, closeQuiz}}>{children}</QuizContext.Provider>;
}

export const useQuiz = () => {
    return useContext(QuizContext);
}